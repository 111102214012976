import React from "react"
import cls from "classnames"
import PropTypes from "prop-types"
import "./index.scss"

const PageTitle = ({ title, className }) => (
  <h1 className={cls("pageTitle", className)}>
    {title}
  </h1>
)

PageTitle.defaultProps = {
  title: ""
}

PageTitle.propTypes = {
  title: PropTypes.string
}

export default PageTitle
