import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUsScreen from "../screens/aboutUs"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <AboutUsScreen />
  </Layout>
)

export default AboutUsPage
