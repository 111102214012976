import React from "react"
import { PageTitle } from "../../components/copy"
import "./index.scss"

const AboutUsScreen = () => (
  <div className="aboutUs-screen u-paddingHorizontal16 u-md-paddingHorizontal0">
    <PageTitle
      className="u-marginTop48"
      title="About Us"
    />
    <div className="u-marginTop40">
      <p className="u-margin0 u-body1">
        Sentra Property Development memiliki cita cita dan visi untuk membangun komunitas yang membutuhkan nilai nilai
        sebagai berikut:
      </p>
      <br />
      <p className="u-margin0 u-body1">
        1. Convenience (Transit Oriented dengan existing KRL)
      </p>
      <p className="u-marginTop12 u-body1">
        2. Safety (Sistem One Gate Entry dan Sekuriti 24 jam)
      </p>
      <p className="u-marginTop12 u-body1">
        3. Comfort (Fasos Fasum mendekati 50% dari luas lahan)
      </p>
      <p className="u-marginTop12 u-body1">
        4. In Cluster Commercial (Pertokoan, pusat keramaian, sekolah, penunjang kesehatan)
      </p>
      <p className="u-marginTop12 u-body1">
        5. Affordability (Harga terjangkau dibawah 200juta)
      </p>
      <p className="u-marginTop12 u-body1">
        6. Investment (Jaminan kenaikan minimal 15% per tahun)
      </p>
    </div>
  </div>
)

export default AboutUsScreen
